.portfolio {
  padding-top: 0;


  // .portfolio__tabs

  &__tabs {
    display: flex;
    align-items: center;
    margin-bottom: 60px;

    @include mobile{
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 0;
    }
  }

  // .portfolio__item

  &__item {
    width: calc((100% - (20px * 4)) / 5);
    transform: translateY(-40%);

    &:not(:last-child) {
      margin-right: 20px;
    }

    @include mobile{
      width: calc((100% - (20px * 4)) / 3);
      margin-bottom: 20px;
      transform: translateY(-20%);
    }
    @include min{
      width: calc((100% - (20px * 4)) / 2);
         }
  }

  // .portfolio__btn

  &__btn {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 32px 10px;
    background-color: $white;
    box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08),
      0px 30px 24px -10px rgba(154, 156, 165, 0.05),
      0px 12px 10px -6px rgba(154, 156, 165, 0.04),
      0px 4px 4px -4px rgba(30, 33, 44, 0.03);
    border-radius: 4px;
    transition: all ease 0.3s;

    &:hover {
      background-color: $primary;

      .portfolio__icon {
        fill: $white;
      }

      .portfolio__text {
        color: $white;
      }
    }

    &--active {
      pointer-events: none;
      background-color: $primary;

      .portfolio__icon {
        fill: $white;
      }

      .portfolio__text {
        color: $white;
      }
    }

    @include tablet {
      min-height: 164px;
    }
  }

  // .portfolio__icon

  &__icon {
    width: 48px;
    height: 48px;
    display: block;
    object-fit: cover;
    fill: $primary;
    margin-bottom: 16px;
    pointer-events: none;
    transition: all ease 0.3s;
  }

  // .portfolio__text

  &__text {
    font-weight: 700;
    color: $grey-600;
    pointer-events: none;
    transition: all ease 0.3s;
  }

  // .portfolio__list

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;

    @include min{
      gap: 0;
      row-gap: 30px;
    }
  }

  // .portfolio__element

  &__element {
    grid-column: 4 span;


    &--visible {
      display: block !important;
    }

    @include mobile{
      grid-column: 6 span;
    }
    @include min{
      grid-column: 12 span;
    }
  }
}