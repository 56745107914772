.project-goal {
  padding-top: 0;

  // .project-goal__container

  &__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;

    @include mobile {
      display: block;
    }
  }

  // .project-goal__left

  &__left {
    grid-column: 7 span;
  }

  // .project-goal__title

  &__title {
    margin-bottom: 40px;
  }

  // .project-goal__text

  &__text {
    max-width: 600px;
    font-size: 18px;
    color: $dark-grey;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  // .project-goal__right

  &__right {
    grid-column: 5 span;
  }

  // .project-goal__info

  &__info {
    box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
    border-radius: 4px;
    padding: 40px 60px;
  }

  // .project-goal__list

  &__list {
    

    @include tablet {
      padding: 40px 20px;
    }
  }

  // .project-goal__item

  &__item {
    display: flex;


    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  // .project-goal__category

  &__category {
    width: 35%;
    line-height: 1.6;
    font-weight: 700;
    color: $dark;
    text-transform: uppercase;

    @include tablet {
      width: 40%;
    }
  }

  // .project-goal__value

  &__value {
    width: 65%;
    line-height: 1.6;
    color: $grey-700;
  }
}