.decisions {
  padding: 80px 0 114px;
  background-color: $grey-200;

  // .decisions__container

  &__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;

    @include mobile {
      display: block;
    }
  }

  // .decisions__left

  &__left {
    grid-column: 5 span;
    position: relative;

    @include mobile {
      display: none;
    }
  }

  // .decisions__img

  &__img {
    position: absolute;
    top: 0;
    left: 0;
  }

  // .decisions__right

  &__right {
    grid-column: 7 span;
    padding-left: 104px;

    @include tablet {
      padding-left: 30px;
    }

    @include min {
      padding-left: 0;
    }
  }

  // .decisions__title

  &__title {
    margin-bottom: 60px;

    @include min {
      text-align: center;
    }
  }

  // .decisions__list

  &__list {
    max-width: 600px;
  }

  // .decisions__item

  &__item {
    position: relative;
    padding-left: 30px;
    line-height: 1.6;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 3px;
      width: 20px;
      height: 20px;
      background-image: url("../img/decisions.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}

.similar {
  padding: 180px 0 120px;

  @include min {
    padding: 40px 0;
  }
}