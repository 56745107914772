.header {
  padding: 23px 0;
  padding-bottom: 21px;
  // .header__container

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }

  &__nav {
    margin-left: -74px;

    @include minidesktop {
      margin-left: 12px;
    }
  }

  &__logo {
    @include minidesktop {
      max-width: 100px;
    }

    @include tablet {
      max-width: none;
      z-index: 6;
    }
  }
}

.header--main {
  background-color: $white;
}

.header--secondary {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}



.header-contacts {

  display: flex;
  align-items: center;

  // .header-contacts__link

  &__link {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @include min {
    display: none;
  }
}

.contacts-link {
  padding-left: 53px;
  margin-left: 36px;
  background-position: left center;
  background-size: 40px 40px;
  background-repeat: no-repeat;

  @include minidesktop {
    margin-left: 10px;
  }

  &:focus {
    outline-offset: 2px;
    outline: 2px solid $primary;
  }

  // .contacts-link__caption

  &__caption {
    font-weight: 700;
    font-size: 14px;
    color: $dark-grey;
  }

  // .contacts-link__value

  &__value {
    font-size: 18px;
    color: $dark;
    transition: all ease 0.3s;

    &:hover {
      color: $primary;
    }

    &:active {
      color: $active;
    }

    @include minidesktop {
      font-size: 16px;
    }

    @include mobile {
      font-size: 14px;
    }
  }
}

.contacts-link--call {
  background-image: url("../img/phone.svg");
}

.contacts-link--mail {
  background-image: url("../img/chat.svg");
}

.logo {
  @include minidesktop {
    max-width: 100px;
  }

  @include tablet {
    max-width: none;
    z-index: 6;
  }
}