.footer {

  background-color: $dark;
  padding: 80px 0;
  color: rgba($color: $white, $alpha: 0.6);

  // .footer__container

  &__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

  }

  &__logo {
    margin-right: 60px;
  }

  // .footer__left

  &__left {
    grid-column: 7 span;
    margin-bottom: 73px;

    @include mobile {
      grid-column: 12 span;
      margin-bottom: 40px;
    }
  }

  &__top {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__form {
    grid-column: 5 span;

    @include mobile {
      grid-column: 12 span;
      margin-bottom: 30px;
    }
  }

  &__address {
    grid-column: 4 span;
    margin-bottom: 60px;

    @include min {
      grid-column: 12 span;
    }
  }

  &__nav {
    grid-column: 8 span;

    @include min {
      grid-column: 12 span;
    }
  }



  // .footer__descr

  &__descr {
    width: 100%;
    font-size: 14px;
    max-width: 495px;
  }

  // .footer__copyright

  &__copyright {
    grid-column: 12 span;
    font-size: 12px;
    color: $white;

    span {
      padding-left: 22px;
      background-image: url(../img/heart.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 16px 16px;
    }
  }
}

.network {

  display: flex;
  align-items: center;
  // .network__item

  &__item {
    &:not(:last-child) {
      margin-right: 16px;
    }
  }

  // .network__link

  &__link {
    display: block;
    width: 24px;
    height: 24px;

    &:hover {
      .network__icon {
        opacity: 1;
      }
    }
  }

  &__icon {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
    opacity: 0.6;
    fill: $white;
    transition: all ease 0.3s;
  }
}

.footer-form {


  // .footer-form__subtitle

  &__subtitle {
    font-size: 24px;
    color: $white;
    font-weight: 700;
    margin-bottom: 24px;
  }

  // .footer-form__btn

  &__btn {
    margin: 0;
    padding: 12px 19px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    letter-spacing: normal;
  }

  // .footer-form__descr

  &__items {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }

  &__descr {}

  &__label {
    margin: 0;

  }

  &__input {
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: rgba($color: $white, $alpha: 0.12) !important;
    border: 1px solid rgba($color: $white, $alpha: 0.25);
  }
}

.contacts-form {

  // .contacts-form__field

  &__field {}
}

.footer-address {

  font-style: normal;
  // .footer-address__title

  &__title {
    font-size: 20px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 13px;

    @include mobile {
      font-size: 18px;
    }
  }

  // .footer-address__item

  &__item {
    display: block;

    &:not(:last-child) {
      margin-bottom: 4px;
    }


  }

  &__item a {
    line-height: 1.6;
    color: rgba($color: $white, $alpha: 0.6);
    margin-bottom: 4px;
    transition: all ease 0.3s;

    &:hover {

      color: rgba($color: $white, $alpha: 1);

    }
  }

  // .footer-address__name

  &__name {
    color: $white;
  }
}

.footer-nav {
  padding-left: 54px;
  display: flex;

  @include min {
    padding-left: 0;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  // .footer-nav__col

  &__col {

    &:not(:last-child) {
      padding-right: 125px;

      @include mobile {
        padding-right: 24px;
      }

    }
  }

  // .footer-nav__title

  &__title {
    font-size: 20px;
    color: $white;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-bottom: 13px;

    @include mobile {
      font-size: 18px;
    }
  }

  // .footer-nav__list

  &__list {
    padding-inline-start: 0;
  }

  // .footer-nav__item

  &__item {


    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }

  // .footer-nav__link

  &__link {
    line-height: 1.6;
    color: rgba($color: $white, $alpha: 0.6);
    transition: all ease 0.3s;

    &:hover {

      color: rgba($color: $white, $alpha: 1);

    }
  }
}