.partners {
  background-color: $grey-200;

  &--light {
    padding-top: 180px;
    background-color: $white;
  }

  &__title {
    margin-bottom: 60px;
  }

  // .partners__list

  &__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 102px;

    @include minidesktop {
      gap: 40px;
    }

  }

  // .partners__item

  &__item {
    @include mobile {
      grid-column: 2 span;
      display: flex;
      justify-content: center;
    }
  }

  // .partners__link

  &__link {}

  // .partners__logo

  &__logo {}
}