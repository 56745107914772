html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul[class] {
  list-style: none;
  padding-inline-start: 0;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  background-color: $white;
  font-size: 16px;
  line-height: 1.5;

  &.lock{
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden; // если используете на сайте position: sticky - уберите эту настройку
}

.is-hidden {
  display: none !important; // stylelint-disable-line declaration-no-important
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

section {
  padding: 120px 0;

  @include mobile {
    padding: 60px 0;
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1260px;
}

.centered {
  text-align: center;
  width: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.main-title {
  margin: 0;
  font-weight: 700;
  font-size: 72px;
  line-height: 1.3;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: $dark;
  margin-bottom: 24px;

  &--home {
    color: $white;
  }

  @include mobile {
    font-size: 56px;
  }
  @include min {
    font-size: 36px;
  }
}

.main-desc {
  margin: 0;
  margin-bottom: 25px;
  font-size: 20px;
  color: $grey-700;

  &--home {
    color: $white;
    margin-bottom: 60px;
  }

  @include mobile {
    font-size: 16px;
  }
}

.btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 15px 40px;
  border-radius: 4px;
  color: $white;
  border: solid 1px;
  transition: all ease-in-out 0.3s;
}


.btn--stroke--light {
  border-color: $white;
  transition: all ease-in-out 0.3s;

  &:focus {
    outline-offset: 2px;
    outline: 2px solid $white;
  }

  &:hover {
    background-color: $primary;
    border-color: $primary;
  }

  &:active {
    background-color: $primary;
    border-color: $primary;
  }
}

.btn--stroke {
  border-color: $primary;
  color: $primary;
  transition: all ease-in-out 0.3s;

  &:focus {
    outline-offset: 2px;
    outline: 2px solid $white;
  }

  &:hover {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }

  &:active {
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}

.btn--fill {
  background-color: $primary;
  border-color: $primary;
  transition: all ease-in-out 0.3s;

  &:focus {
    outline-offset: 2px;
    outline: 2px solid $primary;
  }

  &:hover {
    background-color: $active;
    border-color: $active;
  }

  &:active {
    background-color: $active;
    border-color: $active;
  }
}


.title {
  margin: 0;
  font-weight: 700;
  font-size: 46px;
  line-height: 1.3;
  color: $dark;
  margin-bottom: 24px;

  &--center {
    text-align: center;
  }

  @include mobile {
    font-size: 32px;
  }
  @include min {
    font-size: 28px;
  }
}

.descr {
  margin: 0;
  font-size: 18px;
  color: $grey-700;
  margin-bottom: 60px;

  &--center {
    text-align: center;
    margin-bottom: 60px;
    color: $grey-700;
  }

  @include mobile {
    font-size: 16px;
  }
}

.custom-checkbox {
  margin-bottom: 24px;

  &__input {
    position: absolute;
    opacity: 0;
  }

  &__text {
    position: relative;
    display: inline-block;
    padding-left: 27px;
    font-size: 14px;
    line-height: 150%;
    color: $dark-grey;
  }

  &__text::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 1px;
    display: inline-block;
    border: 1px solid $primary;
    border-radius: 3px;
    width: 16px;
    height: 16px;
    transition: background-color 0.3s ease-in-out-in-out;
  }

  &__text::after {
    content: "";
    position: absolute;
    left: -1px;
    top: 1px;
    width: 16px;
    height: 16px;
    background-image: url("../img/check.svg");
    background-position: center;
    background-size: 10px 8px;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.3s ease-in-out-in-out;
  }

  &__input:checked+&__text::before {
    background-color: $primary;
  }

  &__input:checked+&__text::after {
    opacity: 1;
  }

  &__input:focus+&__text {
    outline: 3px solid $primary;
  }
}

.custom-radio {
  margin-bottom: 24px;


  &__input {
    position: absolute;
    opacity: 0;
  }

  &__text {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    font-size: 16px;
    line-height: 160%;
    color: $dark-grey;
    color: $dark-grey;
    margin-right: 12px;

  }

  &__text::before {
    content: "";
    position: absolute;
    left: -1px;
    top: 4px;
    display: inline-block;
    border: 1px solid $grey-600;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    transition: all 0.3s ease-in-out-in-out;
  }

  &__text::after {
    content: "";
    position: absolute;
    left: -1px;
    top: 4px;
    width: 16px;
    height: 16px;
    background-image: url("../img/check-circle.svg");
    background-position: center;
    background-size: 8px 8px;
    background-repeat: no-repeat;
    opacity: 0;
    transition: opacity 0.3s ease-in-out-in-out;
  }

  &__input:checked+&__text::before {
    border-color: $primary;
  }

  &__input:checked+&__text::after {
    opacity: 1;
  }

  &__input:focus+&__text {
    outline: 3px solid $primary;
  }
}

.to-top {
  position: fixed;
  bottom: 80px;
  right: 50px;
  display: flex;
  align-items: flex-end;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;

  &--active {
    opacity: 1;
    visibility: visible;
  }

  @include minidesktop {
    bottom: 20px;
    right: 20px;
  }


  // .to-top__text

  &__text {
    font-size: 14px;
    line-height: 1.5;
    color: $white;
    text-transform: uppercase;
    margin-right: 16px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  // .to-top__icon

  &__arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: $primary;
    border-radius: 4px;
    transition: background-color ease-in-out 0.3s;

    &:hover {
      background-color: $active;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
}