@mixin desktop {
  @media (min-width: (1025px)) {
    @content;
  }
}
@mixin middledesktop {
  @media (max-width: (1440px)) {
    @content;
  }
}
@mixin minidesktop {
  @media (max-width: (1200px)) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: (992px)) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: (768px)) {
    @content;
  }
}
@mixin min {
  @media (max-width: (576px)) {
    @content;
  }
}
