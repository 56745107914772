.contacts {

  // .contacts__title

  &__title {}

  // .contacts__descr

  &__descr {}

  // .contacts__content

  &__content {
    display: flex;
    justify-content: space-between;

    @include minidesktop {
      justify-content: center;
    }

    @include tablet {
      display: block;
      text-align: center;
      align-items: center;
    }
  }

  // .contacts__img

  &__img {
    margin-right: 20px;
    object-fit: cover;

    @include minidesktop {
      width: 36%;
    }

    @include tablet {
      width: 50%;
      margin-bottom: 30px;
    }
  }
}

.contactus-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 600px;

  @include tablet {
    width: 100%;
  }

  @include min {
    display: block;
  }



  // .contactus-form__field

  &__field {
    width: 48%;
    margin-right: 0px;
    margin-bottom: 24px;

    &--big {
      width: 100%;

      .form-field__input--big {
        height: 126px;
      }
    }

    @include min {
      width: 100%;
    }
  }

  &__set {
    border: none;
    padding: 0;
    padding-left: 24px;
    width: 47.5%;

    .form-field__caption {
      margin-bottom: 21px;
    }

    @include mobile {
      width: 100%;

      padding: 0;
    }
  }

  // .contactus-form__select

  &__select {
    border: 1px solid #D7DADD;
    background-color: #F4F5F7;
    border-radius: 4px;
    width: 100%;
    height: 44px;
    padding-left: 16px;
    color: $dark-grey;
    appearance: none;
    background-image: url("../img/chevron-down.svg");
    background-repeat: no-repeat;
    background-position: right 16px center;
    background-size: 13px;
  }

  &__option {
    padding: 16px;
    line-height: 1.6;
  }

  // .contactus-form__radio

  &__radio {
    &:not(:last-child) {
      margin-right: 4px;
    }
  }

  &__var {
    display: flex;
    flex-wrap: wrap;
  }


  &__choice {}

  &__bottom {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 39px;

    @include tablet {
      width: 100%;
    }
    @include min {
      flex-direction: column;
      align-items: center;
      padding-top: 20px;
    }
  }
}