.slider-nav {
  display: flex;
  align-items: center;
  z-index: 15;

  // .slider-nav__btn

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: $primary;

      .slider-nav__arrow {
        fill: $white;
      }
    }

    @include middledesktop {
      display: none;
    }
  }

  &__arrow {
    display: block;
    object-fit: cover;
    width: 24px;
    height: 24px;
    fill: $dark-grey;
    transition: all 0.3s ease-in-out;
  }


}