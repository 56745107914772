.achieve {

  background-image: url("../img/bg-achieve.png");
  background-repeat: no-repeat;
  background-position: right bottom -350px;


  // .achieve__container

  &__container {}

  // .achieve__list

  &__list {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;
    margin-bottom: 240px;

    @include tablet {
      margin-bottom: 120px;
    }
  }

  // .achieve__item

  &__item {
    grid-column: 3 span;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 70px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 48px 48px;


    &--recom {
      background-image: url("../img/ic-like.svg");
      fill: $grey-600;
    }

    &--aparts {
      background-image: url("../img/ic-painting.svg");
      fill: $grey-600;
    }

    &--spec {
      background-image: url("../img/ic-helmet-grey.svg");
      fill: $grey-600;
    }

    &--finish {
      background-image: url("../img/ic-pantone.svg");
      fill: $grey-600;
    }

    @include tablet {
      grid-column: 6 span;
    }

    @include min {
      grid-column: 12 span;
    }
  }

  // .achieve__value

  &__value {
    font-weight: 700;
    font-size: 46px;
    line-height: 1.3;
    color: $primary;
    margin-bottom: 8px;

    @include minidesktop {
      font-size: 36px;
    }

    @include min {
      font-size: 28px;
    }
  }

  // .achieve__text

  &__text {
    font-size: 14px;
    color: $dark;
  }

  // .achieve__content

  &__content {
    display: flex;

    @include tablet {
      display: block;
    }
  }

  // .achieve__img

  &__img {
    @include minidesktop {
      width: 45%;
      object-fit: cover;
    }

    @include tablet {
      width: 60%;
      object-fit: cover;
    }

    @include tablet {
      width: 60%;
      object-fit: cover;
    }

    @include mobile {
      width: 100%;
    }
  }


  // .achieve__info

  &__info {
    position: relative;
    z-index: 10;
    background-color: $white;
    box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08),
      0px 30px 24px -10px rgba(154, 156, 165, 0.05),
      0px 12px 10px -6px rgba(154, 156, 165, 0.04),
      0px 4px 4px -4px rgba(30, 33, 44, 0.03);
    border-radius: 4px;
    width: 735px;
    left: -30px;
    top: -120px;
    padding: 80px 105px 80px 135px;

    @include tablet {
      width: 100%;
    }
    @include mobile {
      padding: 60px 40px 60px 100px;
    }

    @include min {
      width: 100%;
      left: 0;
      top: 40px;
      padding: 40px 10px 10px 10px;
    }


    &::before {
      content: '';
      position: absolute;
      background-image: url("../img/braces.svg");
      background-repeat: no-repeat;
      fill: $primary;
      width: 28px;
      height: 20px;
      top: 80px;
      left: 80px;

      @include mobile{
        top: 40px;
        left: 60px;
      }

      @include min {
        top: 10px;
        left: 10px;
      }
    }
  }

  // .achieve__blockquote

  &__blockquote {}

  // .achieve__top

  &__top {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.5;
    color: $dark;
    margin-bottom: 60px;

    p:not(:last-child) {
      margin-bottom: 20px;
    }

    @include mobile{
      font-size: 18px;
    }

    @include min {
      font-size: 16px;
      margin-bottom: 40px;
    }
  }

  // .achieve__bottom

  &__bottom {
    position: relative;
    font-size: 14px;
    color: $grey-700;

    &::after {
      content: '';
      position: absolute;
      background-image: url("../img/sign.svg");
      background-repeat: no-repeat;
      background-size: cover;
      fill: $primary;
      width: 100px;
      height: 68px;
      top: 0px;
      right: 0px;

      @include minidesktop {}

      @include min {
        width: 50px;
        height: 34px;
        left: 0px;
        top: 50px;
      }
    }

    @include min {
      padding-bottom: 50px;
    }
  }

  // .achieve__author

  &__author {
    color: $dark;
    line-height: 1.6;
    font-weight: 700;
    margin-bottom: 4px;
  }
}