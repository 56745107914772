.contacts-section {
  background-image: url("../img/contacts-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $grey-700;

  // .contacts-section__container

  &__container {
    display: flex;
    justify-content: flex-end;
  }

  // .contacts-section__form

  &__form {
    background-color: $white;
  }
}

.contacts-form {
  max-width: 495px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 40px;
  border-radius: 4px;
  box-shadow: 0px 60px 80px -20px rgba(30, 33, 44, 0.16),
    0px 26px 24px -10px rgba(30, 33, 44, 0.1),
    0px 12px 10px -6px rgba(30, 33, 44, 0.08),
    0px 4px 4px -4px rgba(30, 33, 44, 0.05);

  @include min {
    padding: 48px 20px;
  }

  // .contacts-form__title

  &__title {
    width: 100%;
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    color: $dark;
    margin-bottom: 24px;
  }

  margin-bottom: 20px;
}

.form-field {



  // .form-field__input

  &__input {}


  // .form-field__input--big

  &__input--big {
    height: 66px;
    resize: none;
  }
}