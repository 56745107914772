.about-section {
  padding-bottom: 60px;

  // .about-section__title

  &__title {}
}

.video-block {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  height: 500px;

  @include min {
    height: 300px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
    background-color: rgba($color: $dark, $alpha: 0.6);
    transition: all ease-in-out 0.3s;
  }

  &--played {
    &::before {
      opacity: 0;
      visibility: hidden;
    }

  }

  // .video-block__play

  &__play {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: $primary;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);
    z-index: 10;
    transition: all ease-in-out 0.3s;

    &:focus {
      outline-offset: 2px;
      outline: 2px solid $primary;
    }

    &:hover {
      background-color: $active;
      border-color: $active;
    }

    &:active {
      background-color: $active;
      border-color: $active;
    }


    svg {
      width: 26px;
      height: 26px;
    }

    &--played {
      opacity: 0;
      visibility: hidden;
    }
  }

  // .video-block__muted

  &__muted {
    position: absolute;
    left: 32px;
    bottom: 32px;
    background-color: rgba($color: #fff, $alpha: 0.12);
    width: 40px;
    height: 40px;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    z-index: 10;

    svg {
      width: 20px;
      height: 20px;
    }

  }

  // .video-block__video

  &__video {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}