@mixin font-face($font-family, $url, $weight, $style) {
  @font-face {
    font-family: "#{$font-family}";
    src: url('../fonts/#{$url}.woff2') format("woff2");
    font-weight: #{$weight};
    font-display: swap;
    font-style: $style;
  }
}

