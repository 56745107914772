.value {
  padding-bottom: 0;

  // .value__title

  &__title {}

  // .value__descr

  &__descr {}

  // .value__list

  &__list {
    --mission-gap: 94px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: calc(var(--mission-gap) * 2);
    margin-bottom: 27px;

    @include tablet {
      --mission-gap: 30px;
    }

    @include min {
      display: block;
    }
  }

  // .value__item

  &__item {
    text-align: center;
    padding-top: 70px;
    background-size: 48px 48px;
    background-position: top center;
    background-repeat: no-repeat;
    position: relative;

    &:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:not(:last-child) {
      &::after {
        content: '';
        position: absolute;
        right: calc(-1 * var(--mission-gap));
        top: 0;
        width: 1px;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../img/divider.png);
      }
    }
  }



  // .value__subtitle

  &__subtitle {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: $dark;
    margin-bottom: 8px;
  }

  // .value__text

  &__text {
    line-height: 1.56;
    color: $grey-700;

    @include mobile {
      display: none;
    }

    @include min {
      display: block;
      font-size: 16px;
    }
  }

  &__item {
    @include min {
      margin-bottom: 40px;
    }
  }

  // .value__item--quality

  &__item--quality {
    background-image: url(../img/like.svg);
  }

  // .value__item--safety

  &__item--safety {
    background-image: url(../img/safety.svg);
  }

  // .value__item--comfort

  &__item--comfort {
    background-image: url(../img/comfort.svg);
  }
}

.question-form {
  position: relative;
  top: 92px;
  padding: 48px 60px;
  box-shadow:
    0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  background-color: $white;

  @include mobile {
    top: 56px;
    padding: 10px;
  }

  @include min {
    top: 36px;
  }

  // .question-form__title

  &__title {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    color: $dark;
    margin-bottom: 32px;

    @include min {
      font-size: 22px;
    }
  }

  // .question-form__content

  &__content {
    display: flex;
    align-items: flex-end;

    @include tablet {
      flex-wrap: wrap;
      justify-content: space-between;

      &>* {
        margin-right: 0 !important;
        margin-bottom: 20px;
        width: 49%;
        flex-grow: initial;

        .form-field__input {
          width: 100%;
        }

      }
    }

    @include min {
      &>* {
        margin-right: 0 !important;
        margin-bottom: 20px;
        width: 100%;
        flex-grow: initial;
      }
    }
  }
}