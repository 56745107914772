.porfolio-section {
  padding: 120px 0;




  // .porfolio-section__top

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 48px;
  }

  // .porfolio-section__title

  &__title {
    margin: 0;
    max-width: 710px;
    padding-bottom: 12px;

    @include mobile {
      font-size: 28px;
    }
  }
}

.portfolio-section {

  // .portfolio-section__slider

  &__slider {
    padding: 50px 10px;
    margin: -50px -10px;
  }
}

.portfolio-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  background-color: $white;
  overflow: hidden;
  transition: all ease-in-out 0.3s;

  &:hover {
    .portfolio-item__content {
      transform: translateY(-70%);
    }

    .portfolio-item__img {
      transform: translateY(-2em);
    }
  }

  // .portfolio-item__img

  &__img {
    margin-bottom: 16px;
    transform: translateY(0);
    transition: all ease-in-out 0.3s;
  }

  // .portfolio-item__content

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 15px 10px;
    background-color: $white;
    transition: all ease-in-out 0.3s;
  }

  // .portfolio-item__title

  &__title {
    margin: 0;
    font-size: 20px;
    font-weight: 700;
    color: $dark;
    text-align: center;
    margin-bottom: 4px;

    @include minidesktop {
      font-size: 18px;
    }

    @include tablet {
      min-height: 54px;
    }

  }

  // .portfolio-item__desc

  &__desc {
    display: inline-block;
    font-size: 14px;
    text-align: center;
    color: $grey-700;
  }

  // .portfolio-item__btn

  &__btn {
    position: absolute;
    margin-top: 24px;
    font-size: 14px;
    padding: 12px 32px;
    bottom: 0;
    transform: translateY(100%);


    // display: flex;
    // align-items: center;
    // justify-content: center;
    // border-radius: 100%;
    // width: 48px;
    // height: 48px;
    // transition: all 0.3s ease-in-out;
  }
}

.portfolio-section {

  // .services-section__learn

  &__learn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 85px 0 0 0;

    @include min {
      display: block;
      text-align: center;
    }
  }

  // .services-section__subtitle

  &__subtitle {
    margin-right: 40px;
    font-size: 28px;
    font-weight: 700;
    color: $dark;

    @include mobile {
      font-size: 22px;
    }

    @include min {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }



}