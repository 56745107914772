.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 0;

  // .breadcrumbs__item

  &__item {
    position: relative;

    &:not(:last-child) {
      margin-right: 22px;

      &::after {
        content: "/";
        position: absolute;
        right: -14px;
        top: 0;
        color: $grey-600;
      }
    }
  }

  // .breadcrumbs__link

  &__link {
    font-size: 14px;
    color: $dark-grey;

    &:not([href]) {
      color: $grey-600;
    }

    &:focus {
      outline-offset: 2px;
      outline: 2px solid $primary;
    }

    &:hover {
      color: $primary;
      &:not([href]) {
        color: $grey-600;
      }
    }

    &:active {
      color: $active;
    }
  }
}