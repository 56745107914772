.burger{
  display: none;
  cursor: pointer;
  border: none;
  position: relative;
}


@include tablet {
  .burger {
    display: block; 
    width: 40px;
    height: 20px;
    top: 0;
    right: 15px;
    z-index: 5;

  
    & span {
      position: absolute;
      background-color: $dark-grey;
      left: 0;
      width: 100%;
      height: 2px;
      top: 9px;
      transition: all 0.3s ease;
    }
  }
  
  
  
  .burger:before,
  .burger:after {
    content: '';
    background-color: $dark-grey;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease;
  }
  
  .burger:before {
    top: 0;
  }
  
  .burger:after {
    bottom: 0;
  }
  
  .burger.open span {
    transform: translateY(-100px)
  }
  
  .burger.open::before {
    transform: rotate(45deg);
    top: 9px;
  }
  
  .burger.open::after {
    transform: rotate(-45deg);
    bottom: 9px;
  }
}


