.form-field {

  display: flex;
  flex-direction: column;
  align-items: start;

  margin-right: 24px;

  @include min {
    margin-right: 0;
  }

  // .form-field__caption

  &__caption {
    font-size: 14px;
    color: $dark-grey;
    margin-bottom: 8px;
    text-align: left;
  }

  &__input {
    border: 1px solid #D7DADD;
    background-color: #F4F5F7;
    border-radius: 4px;
    width: 260px;
    height: 44px;
    padding-left: 16px;

  }

  &--message {
    flex-grow: 1;

    .form-field__input {
      width: 100%;
    }

    @include tablet {
      flex-grow: 0;
    }
  }

  &__btn {
    padding: 11px 28px;
  }

  &--dark {
    width: 100%;

    .form-field__input {
      width: 100%;
      background-color: $grey-200;
    }
  }
}