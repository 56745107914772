.hero {
  padding-top: 0;
  padding-bottom: 0;




  // .hero__content

  &__content {
    max-width: 590px;
  }

  &__next {
    position: absolute;
    right: 32px;
    top: 50%;
    background-color: $white;
    z-index: 10;

    @include middledesktop {
      display: none;
    }
  }

  &__prev {
    position: absolute;
    left: 32px;
    top: 50%;
    background-color: $white;
    z-index: 10;

    @include middledesktop {
      display: none;
    }
  }

  // .hero__btns

  &__btns {
    .btn {
      &:not(:last-child) {
        margin-right: 24px;

        @include min {
          margin-right: 0;
          margin-bottom: 20px;
        }
      }
    }
  }

  &__pag {
    counter-reset: pag;
    display: flex;
    left: calc((100% - 1260px) / 2) !important;
    bottom: 120px !important;

    .swiper-pagination-bullet {
      width: 178px;
      height: auto;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      border-radius: 0;
      opacity: 1;
      text-align: left;

      &::before {
        counter-increment: pag;
        content: "0"counter(pag);
        font-weight: 700;
        font-size: 28px;
        line-height: 1.5;
        color: rgba(#fff, 0.3);
        transition: color 0.3s ease-in-out;
      }
    }

    @include middledesktop {
      left: 3% !important;
    }

    @include minidesktop {
      left: 15px !important;
    }

    @include mobile {
      width: calc(100% - 30px) !important;
      bottom: 60px !important;
    }

    @include min {
      display: none;
    }
  }

  &__bar {
    position: relative;
    overflow: hidden;
    border-radius: 100px;
    width: 100%;
    height: 2px;
    background-color: rgba(#fff, 0.3);

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $white;
      transform: scaleX(0);
      transform-origin: left;
      transition: transform var(--hero-slider-speed) ease-in-out;
    }
  }
}

.swiper-pagination-bullet-active {
  &::before {
    color: $white !important;
  }

  .hero__bar {
    &::after {
      transform: scaleX(1);

      color: $white !important;
    }
  }
}

.hero-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.hero-slide {
  background-image: url(../img/bg-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $dark-grey;
  padding: 220px 0 354px 0;

  @include mobile {
    padding: 200px 0 240px 0;
  }

  @include min {
    padding: 120px 0 120px 0;
  }
}