.offer {
  padding: 128px 0;
  background-color: $white;

  @include tablet {
    padding: 80px 0;
  }

  // .offer__container

  &__container {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 30px;

    @include tablet {
      display: block;
    }
  }

  // .offer__img-box

  &__img-box {
    grid-column: 7 span;
  }

  // .offer__img

  &__img {}

  // .offer__content

  &__content {
    padding-top: 5px;
    grid-column: 5 span;
    padding-left: 75px;

    @include minidesktop {
      padding-left: 35px;
    }

    @include tablet {
      padding-top: 30px;
    }
  }

  // .offer__title

  &__title {
    font-size: 46px;
    font-family: "Lato", sans-serif;
    font-weight: 900;
    color: $dark;
    line-height: 1.3;
    margin-bottom: 55px;
  }

  // .offer__accordion

  &__accordion {
    &:not(:last-of-type) {
      margin-bottom: 13px;
    }

    .accordion__control {
      position: relative;
      text-align: left;
      padding-right: 20px;
      padding-left: 44px;
    }

    .accordion-title {

      font-weight: 700;
      font-size: 28px;
      color: $dark;
      line-height: 1.5;
    }

    .accordion__content {
      padding: 13px 0;
      padding-right: 20px;
      padding-left: 44px;

      p {
        margin: 0;
        font-size: 16px;
        line-height: 1.6;
        color: $grey-700;
      }
    }

    .accordion__icon {
      position: absolute;
      display: block;
      width: 22px;
      height: 22px;
      left: 0;
      top: 0;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        border-radius: 100px;
        width: 100%;
        height: 2px;
        background-color: $primary;
        transform: translateY(-50%);
      }

      &::after {
        content: "";
        position: absolute;
        left: 50%;
        top: 0;
        border-radius: 100px;
        width: 2px;
        height: 100%;
        background-color: $primary;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }

  .is-open .accordion__icon {
    transform: none;

    &::after {
      opacity: 0;
    }
  }
}