.testimonials {
  background-color: $grey-200;
  padding-bottom: 0;

  @include mobile {
    padding-bottom: 60px;
    ;
  }

  &__container {
    display: flex;
    justify-content: space-between;
  }

  // .testimonials__title

  &__title {
    margin-bottom: 60px;
  }

  // .testimonials__content

  &__content {
    max-width: 600px;

    @include tablet {
      max-width: 400px;
    }

    @include mobile {
      width: 100%;
      max-width: none;
    }
  }

  // .testimonials__slider

  &__slider {}

  // .testimonials__item

  &__item {}

  &__img-wrapper {
    width: 72px;
    height: 72px;
    overflow: hidden;
    border-radius: 100%;
    margin-bottom: 24px;
  }

  // .testimonials__img

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  // .testimonials__text

  &__text {
    line-height: 1.6;
    color: $dark-grey;
    margin-bottom: 24px;
  }

  // .testimonials__author

  &__author {
    display: block;
    line-height: 1.6;
    font-weight: 700;
    color: $dark;
    margin-bottom: 4px;
    padding-right: 110px;
  }

  // .testimonials__position

  &__position {
    display: block;
    font-size: 14px;
    color: $grey-700;
    padding-right: 110px;
  }

  // .testimonials__photo

  &__photo {
    margin-bottom: -60px;

    @include minidesktop {
      width: 380px;
      object-fit: cover;
    }

    @include tablet {
      width: 300px;
    }

    @include mobile {
      display: none;
    }
  }

  &__slider-nav {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  &__btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 48px;
    height: 48px;
    transition: all 0.3s ease-in-out
  }
}