$accent: #145855;
$primary: #FF5A30;
$dark: #1E212C;
$dark-grey: #424551;
$grey-700: #787A80;
$grey-600: #9A9CA5;
$grey-400: #D7DADD;
$grey-300: #E5E8ED;
$grey-200: #F4F5F7;
$white: #fff;
$black: #000;
$active: #FC3300;


root{--hero-slider-speed: 500;}

