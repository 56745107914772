.page-hero {
  padding-top: 152px;
  padding-bottom: 180px;
  background-repeat: no-repeat;
  background-color: $grey-200;
  background-size: contain;
  background-position: right center;

  &--services {
    background-image: url("../img/services-page.png");

  }

  &--work {
    background-image: url("../img/bg-work.png");

  }

  &--inner {
    background-image: url("../img/bg-services-detail.png");
    background-position: right -40px center;
  }

  &--project {
    background-color: $white;
    background-image: url("../img/bg-project.png");
  }

  &--about {
    background-color: $grey-200;
    background-image: url("../img/bg-about.png");
  }

  &--news {
    background-color: $grey-200;
    background-image: url("../img/bg-news.png");
  }

  &--contacts {
    background-color: $grey-200;
    background-image: url("../img/bg-contacts.png");
  }

  @include minidesktop {
    background-image: none;
    padding-bottom: 90px;
  }

  // .page-hero__breadcrumbs

  &__breadcrumbs {
    margin-bottom: 40px;
  }

  // .page-hero__title

  &__title {
    max-width: 600px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 72px;
    line-height: 1.3;
    color: $dark;
    letter-spacing: 1px;
    margin-bottom: 24px;

    @include mobile {
      font-size: 56px;
    }

    @include min {
      font-size: 36px;
    }
  }

  // .page-hero__descr

  &__descr {
    max-width: 600px;
    font-size: 20px;
    line-height: 1.5;
    color: $grey-700;

    @include mobile {
      font-size: 16px;
    }
  }
}