root {
  --hero-slider-speed: 500;
}

.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/../fonts/UbuntuRegular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Ubuntu";
  src: url("../fonts/../fonts/UbuntuBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/../fonts/LatoBold.woff2") format("woff2");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("../fonts/../fonts/LatoBlack.woff2") format("woff2");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul[class] {
  list-style: none;
  padding-inline-start: 0;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  font-weight: 400;
  background-color: #fff;
  font-size: 16px;
  line-height: 1.5;
}
body.lock {
  overflow: hidden;
}

img {
  max-width: 100%;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important;
}

button {
  border: none;
  background: transparent;
  cursor: pointer;
}

section {
  padding: 120px 0;
}
@media (max-width: 768px) {
  section {
    padding: 60px 0;
  }
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.container {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1260px;
}

.centered {
  text-align: center;
  width: 100%;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.main-title {
  margin: 0;
  font-weight: 700;
  font-size: 72px;
  line-height: 1.3;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #1E212C;
  margin-bottom: 24px;
}
.main-title--home {
  color: #fff;
}
@media (max-width: 768px) {
  .main-title {
    font-size: 56px;
  }
}
@media (max-width: 576px) {
  .main-title {
    font-size: 36px;
  }
}

.main-desc {
  margin: 0;
  margin-bottom: 25px;
  font-size: 20px;
  color: #787A80;
}
.main-desc--home {
  color: #fff;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .main-desc {
    font-size: 16px;
  }
}

.btn {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.5px;
  padding: 15px 40px;
  border-radius: 4px;
  color: #fff;
  border: solid 1px;
  transition: all ease-in-out 0.3s;
}

.btn--stroke--light {
  border-color: #fff;
  transition: all ease-in-out 0.3s;
}
.btn--stroke--light:focus {
  outline-offset: 2px;
  outline: 2px solid #fff;
}
.btn--stroke--light:hover {
  background-color: #FF5A30;
  border-color: #FF5A30;
}
.btn--stroke--light:active {
  background-color: #FF5A30;
  border-color: #FF5A30;
}

.btn--stroke {
  border-color: #FF5A30;
  color: #FF5A30;
  transition: all ease-in-out 0.3s;
}
.btn--stroke:focus {
  outline-offset: 2px;
  outline: 2px solid #fff;
}
.btn--stroke:hover {
  background-color: #FF5A30;
  border-color: #FF5A30;
  color: #fff;
}
.btn--stroke:active {
  background-color: #FF5A30;
  border-color: #FF5A30;
  color: #fff;
}

.btn--fill {
  background-color: #FF5A30;
  border-color: #FF5A30;
  transition: all ease-in-out 0.3s;
}
.btn--fill:focus {
  outline-offset: 2px;
  outline: 2px solid #FF5A30;
}
.btn--fill:hover {
  background-color: #FC3300;
  border-color: #FC3300;
}
.btn--fill:active {
  background-color: #FC3300;
  border-color: #FC3300;
}

.title {
  margin: 0;
  font-weight: 700;
  font-size: 46px;
  line-height: 1.3;
  color: #1E212C;
  margin-bottom: 24px;
}
.title--center {
  text-align: center;
}
@media (max-width: 768px) {
  .title {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .title {
    font-size: 28px;
  }
}

.descr {
  margin: 0;
  font-size: 18px;
  color: #787A80;
  margin-bottom: 60px;
}
.descr--center {
  text-align: center;
  margin-bottom: 60px;
  color: #787A80;
}
@media (max-width: 768px) {
  .descr {
    font-size: 16px;
  }
}

.custom-checkbox {
  margin-bottom: 24px;
}
.custom-checkbox__input {
  position: absolute;
  opacity: 0;
}
.custom-checkbox__text {
  position: relative;
  display: inline-block;
  padding-left: 27px;
  font-size: 14px;
  line-height: 150%;
  color: #424551;
}
.custom-checkbox__text::before {
  content: "";
  position: absolute;
  left: -1px;
  top: 1px;
  display: inline-block;
  border: 1px solid #FF5A30;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  transition: background-color 0.3s ease-in-out-in-out;
}
.custom-checkbox__text::after {
  content: "";
  position: absolute;
  left: -1px;
  top: 1px;
  width: 16px;
  height: 16px;
  background-image: url("../img/check.svg");
  background-position: center;
  background-size: 10px 8px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease-in-out-in-out;
}
.custom-checkbox__input:checked + .custom-checkbox__text::before {
  background-color: #FF5A30;
}
.custom-checkbox__input:checked + .custom-checkbox__text::after {
  opacity: 1;
}
.custom-checkbox__input:focus + .custom-checkbox__text {
  outline: 3px solid #FF5A30;
}

.custom-radio {
  margin-bottom: 24px;
}
.custom-radio__input {
  position: absolute;
  opacity: 0;
}
.custom-radio__text {
  position: relative;
  display: inline-block;
  padding-left: 24px;
  font-size: 16px;
  line-height: 160%;
  color: #424551;
  color: #424551;
  margin-right: 12px;
}
.custom-radio__text::before {
  content: "";
  position: absolute;
  left: -1px;
  top: 4px;
  display: inline-block;
  border: 1px solid #9A9CA5;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  transition: all 0.3s ease-in-out-in-out;
}
.custom-radio__text::after {
  content: "";
  position: absolute;
  left: -1px;
  top: 4px;
  width: 16px;
  height: 16px;
  background-image: url("../img/check-circle.svg");
  background-position: center;
  background-size: 8px 8px;
  background-repeat: no-repeat;
  opacity: 0;
  transition: opacity 0.3s ease-in-out-in-out;
}
.custom-radio__input:checked + .custom-radio__text::before {
  border-color: #FF5A30;
}
.custom-radio__input:checked + .custom-radio__text::after {
  opacity: 1;
}
.custom-radio__input:focus + .custom-radio__text {
  outline: 3px solid #FF5A30;
}

.to-top {
  position: fixed;
  bottom: 80px;
  right: 50px;
  display: flex;
  align-items: flex-end;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: all ease-in-out 0.3s;
}
.to-top--active {
  opacity: 1;
  visibility: visible;
}
@media (max-width: 1200px) {
  .to-top {
    bottom: 20px;
    right: 20px;
  }
}
.to-top__text {
  font-size: 14px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;
  margin-right: 16px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.to-top__arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #FF5A30;
  border-radius: 4px;
  transition: background-color ease-in-out 0.3s;
}
.to-top__arrow:hover {
  background-color: #FC3300;
}
.to-top__icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
}

.header {
  padding: 23px 0;
  padding-bottom: 21px;
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header__nav {
  margin-left: -74px;
}
@media (max-width: 1200px) {
  .header__nav {
    margin-left: 12px;
  }
}
@media (max-width: 1200px) {
  .header__logo {
    max-width: 100px;
  }
}
@media (max-width: 992px) {
  .header__logo {
    max-width: none;
    z-index: 6;
  }
}

.header--main {
  background-color: #fff;
}

.header--secondary {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.header-contacts {
  display: flex;
  align-items: center;
}
.header-contacts__link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
@media (max-width: 576px) {
  .header-contacts {
    display: none;
  }
}

.contacts-link {
  padding-left: 53px;
  margin-left: 36px;
  background-position: left center;
  background-size: 40px 40px;
  background-repeat: no-repeat;
}
@media (max-width: 1200px) {
  .contacts-link {
    margin-left: 10px;
  }
}
.contacts-link:focus {
  outline-offset: 2px;
  outline: 2px solid #FF5A30;
}
.contacts-link__caption {
  font-weight: 700;
  font-size: 14px;
  color: #424551;
}
.contacts-link__value {
  font-size: 18px;
  color: #1E212C;
  transition: all ease 0.3s;
}
.contacts-link__value:hover {
  color: #FF5A30;
}
.contacts-link__value:active {
  color: #FC3300;
}
@media (max-width: 1200px) {
  .contacts-link__value {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .contacts-link__value {
    font-size: 14px;
  }
}

.contacts-link--call {
  background-image: url("../img/phone.svg");
}

.contacts-link--mail {
  background-image: url("../img/chat.svg");
}

@media (max-width: 1200px) {
  .logo {
    max-width: 100px;
  }
}
@media (max-width: 992px) {
  .logo {
    max-width: none;
    z-index: 6;
  }
}

.nav__list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 992px) {
  .nav__list {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.nav__item:not(:last-child) {
  margin-right: 40px;
}
@media (max-width: 992px) {
  .nav__item:not(:last-child) {
    margin-right: 0;
  }
}
@media (max-width: 992px) {
  .nav__item {
    padding: 20px 20px;
  }
}
.nav__link {
  font-weight: 700;
  line-height: 1.6;
  color: #424551;
  transition: all ease-in-out 0.3s;
}
.nav__link:focus {
  outline-offset: 2px;
  outline: 2px solid #FF5A30;
}
.nav__link:hover {
  color: #FF5A30;
}
.nav__link:active {
  color: #FC3300;
}
.nav__link--current {
  color: #FF5A30;
}
@media (max-width: 992px) {
  .nav__link {
    font-size: 32px;
  }
}
@media (max-width: 576px) {
  .nav__link {
    font-size: 28px;
  }
}
@media (max-width: 992px) {
  .nav {
    position: absolute;
    top: 0;
    left: -100%;
    width: 80%;
    height: 100vh;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.98);
    padding: 100px 30px 30px 30px;
    transition: left 0.3s ease-in-out 0s;
  }
}
@media (max-width: 576px) {
  .nav {
    width: 100%;
  }
}
.nav.open {
  left: 0;
}

.hero {
  padding-top: 0;
  padding-bottom: 0;
}
.hero__content {
  max-width: 590px;
}
.hero__next {
  position: absolute;
  right: 32px;
  top: 50%;
  background-color: #fff;
  z-index: 10;
}
@media (max-width: 1440px) {
  .hero__next {
    display: none;
  }
}
.hero__prev {
  position: absolute;
  left: 32px;
  top: 50%;
  background-color: #fff;
  z-index: 10;
}
@media (max-width: 1440px) {
  .hero__prev {
    display: none;
  }
}
.hero__btns .btn:not(:last-child) {
  margin-right: 24px;
}
@media (max-width: 576px) {
  .hero__btns .btn:not(:last-child) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.hero__pag {
  counter-reset: pag;
  display: flex;
  left: calc((100% - 1260px) / 2) !important;
  bottom: 120px !important;
}
.hero__pag .swiper-pagination-bullet {
  width: 178px;
  height: auto;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  border-radius: 0;
  opacity: 1;
  text-align: left;
}
.hero__pag .swiper-pagination-bullet::before {
  counter-increment: pag;
  content: "0" counter(pag);
  font-weight: 700;
  font-size: 28px;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.3);
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1440px) {
  .hero__pag {
    left: 3% !important;
  }
}
@media (max-width: 1200px) {
  .hero__pag {
    left: 15px !important;
  }
}
@media (max-width: 768px) {
  .hero__pag {
    width: calc(100% - 30px) !important;
    bottom: 60px !important;
  }
}
@media (max-width: 576px) {
  .hero__pag {
    display: none;
  }
}
.hero__bar {
  position: relative;
  overflow: hidden;
  border-radius: 100px;
  width: 100%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}
.hero__bar::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: scaleX(0);
  transform-origin: left;
  transition: transform var(--hero-slider-speed) ease-in-out;
}

.swiper-pagination-bullet-active::before {
  color: #fff !important;
}
.swiper-pagination-bullet-active .hero__bar::after {
  transform: scaleX(1);
  color: #fff !important;
}

.hero-slider {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.hero-slide {
  background-image: url(../img/bg-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #424551;
  padding: 220px 0 354px 0;
}
@media (max-width: 768px) {
  .hero-slide {
    padding: 200px 0 240px 0;
  }
}
@media (max-width: 576px) {
  .hero-slide {
    padding: 120px 0 120px 0;
  }
}

.about-section {
  padding-bottom: 60px;
}
.video-block {
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  height: 500px;
}
@media (max-width: 576px) {
  .video-block {
    height: 300px;
  }
}
.video-block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background-color: rgba(30, 33, 44, 0.6);
  transition: all ease-in-out 0.3s;
}
.video-block--played::before {
  opacity: 0;
  visibility: hidden;
}
.video-block__play {
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #FF5A30;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  transform: translate(-50%, -50%);
  z-index: 10;
  transition: all ease-in-out 0.3s;
}
.video-block__play:focus {
  outline-offset: 2px;
  outline: 2px solid #FF5A30;
}
.video-block__play:hover {
  background-color: #FC3300;
  border-color: #FC3300;
}
.video-block__play:active {
  background-color: #FC3300;
  border-color: #FC3300;
}
.video-block__play svg {
  width: 26px;
  height: 26px;
}
.video-block__play--played {
  opacity: 0;
  visibility: hidden;
}
.video-block__muted {
  position: absolute;
  left: 32px;
  bottom: 32px;
  background-color: rgba(255, 255, 255, 0.12);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.video-block__muted svg {
  width: 20px;
  height: 20px;
}
.video-block__video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.value {
  padding-bottom: 0;
}
.value__list {
  --mission-gap: 94px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(var(--mission-gap) * 2);
  margin-bottom: 27px;
}
@media (max-width: 992px) {
  .value__list {
    --mission-gap: 30px;
  }
}
@media (max-width: 576px) {
  .value__list {
    display: block;
  }
}
.value__item {
  text-align: center;
  padding-top: 70px;
  background-size: 48px 48px;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}
.value__item:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.value__item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: calc(-1 * var(--mission-gap));
  top: 0;
  width: 1px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/divider.png);
}
.value__subtitle {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #1E212C;
  margin-bottom: 8px;
}
.value__text {
  line-height: 1.56;
  color: #787A80;
}
@media (max-width: 768px) {
  .value__text {
    display: none;
  }
}
@media (max-width: 576px) {
  .value__text {
    display: block;
    font-size: 16px;
  }
}
@media (max-width: 576px) {
  .value__item {
    margin-bottom: 40px;
  }
}
.value__item--quality {
  background-image: url(../img/like.svg);
}
.value__item--safety {
  background-image: url(../img/safety.svg);
}
.value__item--comfort {
  background-image: url(../img/comfort.svg);
}

.question-form {
  position: relative;
  top: 92px;
  padding: 48px 60px;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  background-color: #fff;
}
@media (max-width: 768px) {
  .question-form {
    top: 56px;
    padding: 10px;
  }
}
@media (max-width: 576px) {
  .question-form {
    top: 36px;
  }
}
.question-form__title {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  color: #1E212C;
  margin-bottom: 32px;
}
@media (max-width: 576px) {
  .question-form__title {
    font-size: 22px;
  }
}
.question-form__content {
  display: flex;
  align-items: flex-end;
}
@media (max-width: 992px) {
  .question-form__content {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .question-form__content > * {
    margin-right: 0 !important;
    margin-bottom: 20px;
    width: 49%;
    flex-grow: initial;
  }
  .question-form__content > * .form-field__input {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .question-form__content > * {
    margin-right: 0 !important;
    margin-bottom: 20px;
    width: 100%;
    flex-grow: initial;
  }
}

.services-section {
  padding-top: 212px;
  background-image: url("../img/bg-services.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
@media (max-width: 768px) {
  .services-section {
    padding-top: 120px;
  }
}
@media (max-width: 576px) {
  .services-section {
    text-align: center;
  }
}

.services-list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
  margin-bottom: 85px;
}
@media (max-width: 576px) {
  .services-list {
    gap: 0;
    margin-bottom: 40px;
  }
}
.services-list__item {
  grid-column: 3 span;
}
@media (max-width: 992px) {
  .services-list__item {
    grid-column: 6 span;
  }
}
@media (max-width: 576px) {
  .services-list__item {
    grid-column: 12 span;
    margin-bottom: 30px;
  }
}
.services-list__content {
  position: relative;
  z-index: 10;
}

.services-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 86px 10px;
  padding-bottom: 90px;
  position: relative;
  background: #fff;
  border: 1px solid #E5E8ED;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
}
@media (max-width: 576px) {
  .services-item {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.services-item::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  background-color: #1E212C;
  border-radius: inherit;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.services-item::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  border-radius: inherit;
  background-color: rgba(30, 33, 44, 0.6);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.services-item--contruction::before {
  background-image: url(../img/services-img1.jpg);
}
.services-item--dev::before {
  background-image: url(../img/services-img2.jpg);
}
.services-item--interior::before {
  background-image: url(../img/services-img3.jpg);
}
.services-item--repairs::before {
  background-image: url(../img/services-img4.jpg);
}
.services-item__icon {
  margin-bottom: 23px;
  width: 52px;
  height: 52px;
  fill: #FF5A30;
  object-fit: cover;
  z-index: 10;
  transition: fill 0.3s ease-in-out;
}
.services-item__title {
  font-size: 20px;
  font-weight: 700;
  color: #1E212C;
  text-align: center;
  z-index: 10;
  transition: color 0.3s ease-in-out;
}
@media (max-width: 1200px) {
  .services-item__title {
    font-size: 18px;
  }
}
.services-item:hover::before {
  opacity: 1;
}
.services-item:hover::after {
  opacity: 1;
}
.services-item:hover .services-item__icon {
  fill: #fff;
}
.services-item:hover .services-item__title {
  color: #fff;
}

.services-section__learn {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 576px) {
  .services-section__learn {
    display: block;
  }
}
.services-section__subtitle {
  margin-right: 40px;
  font-size: 28px;
  font-weight: 700;
  color: #1E212C;
}
@media (max-width: 768px) {
  .services-section__subtitle {
    font-size: 22px;
  }
}
@media (max-width: 576px) {
  .services-section__subtitle {
    margin-bottom: 20px;
    margin-right: 0;
  }
}

.form-field {
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: 24px;
}
@media (max-width: 576px) {
  .form-field {
    margin-right: 0;
  }
}
.form-field__caption {
  font-size: 14px;
  color: #424551;
  margin-bottom: 8px;
  text-align: left;
}
.form-field__input {
  border: 1px solid #D7DADD;
  background-color: #F4F5F7;
  border-radius: 4px;
  width: 260px;
  height: 44px;
  padding-left: 16px;
}
.form-field--message {
  flex-grow: 1;
}
.form-field--message .form-field__input {
  width: 100%;
}
@media (max-width: 992px) {
  .form-field--message {
    flex-grow: 0;
  }
}
.form-field__btn {
  padding: 11px 28px;
}
.form-field--dark {
  width: 100%;
}
.form-field--dark .form-field__input {
  width: 100%;
  background-color: #F4F5F7;
}

.porfolio-section {
  padding: 120px 0;
}
.porfolio-section__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 48px;
}
.porfolio-section__title {
  margin: 0;
  max-width: 710px;
  padding-bottom: 12px;
}
@media (max-width: 768px) {
  .porfolio-section__title {
    font-size: 28px;
  }
}

.portfolio-section__slider {
  padding: 50px 10px;
  margin: -50px -10px;
}

.portfolio-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  background-color: #fff;
  overflow: hidden;
  transition: all ease-in-out 0.3s;
}
.portfolio-item:hover .portfolio-item__content {
  transform: translateY(-70%);
}
.portfolio-item:hover .portfolio-item__img {
  transform: translateY(-2em);
}
.portfolio-item__img {
  margin-bottom: 16px;
  transform: translateY(0);
  transition: all ease-in-out 0.3s;
}
.portfolio-item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 15px 10px;
  background-color: #fff;
  transition: all ease-in-out 0.3s;
}
.portfolio-item__title {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #1E212C;
  text-align: center;
  margin-bottom: 4px;
}
@media (max-width: 1200px) {
  .portfolio-item__title {
    font-size: 18px;
  }
}
@media (max-width: 992px) {
  .portfolio-item__title {
    min-height: 54px;
  }
}
.portfolio-item__desc {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  color: #787A80;
}
.portfolio-item__btn {
  position: absolute;
  margin-top: 24px;
  font-size: 14px;
  padding: 12px 32px;
  bottom: 0;
  transform: translateY(100%);
}

.portfolio-section__learn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 85px 0 0 0;
}
@media (max-width: 576px) {
  .portfolio-section__learn {
    display: block;
    text-align: center;
  }
}
.portfolio-section__subtitle {
  margin-right: 40px;
  font-size: 28px;
  font-weight: 700;
  color: #1E212C;
}
@media (max-width: 768px) {
  .portfolio-section__subtitle {
    font-size: 22px;
  }
}
@media (max-width: 576px) {
  .portfolio-section__subtitle {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.slider-nav {
  display: flex;
  align-items: center;
  z-index: 15;
}
.slider-nav__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  transition: all 0.3s ease-in-out;
}
.slider-nav__btn:hover {
  background-color: #FF5A30;
}
.slider-nav__btn:hover .slider-nav__arrow {
  fill: #fff;
}
@media (max-width: 1440px) {
  .slider-nav__btn {
    display: none;
  }
}
.slider-nav__arrow {
  display: block;
  object-fit: cover;
  width: 24px;
  height: 24px;
  fill: #424551;
  transition: all 0.3s ease-in-out;
}

.partners {
  background-color: #F4F5F7;
}
.partners--light {
  padding-top: 180px;
  background-color: #fff;
}
.partners__title {
  margin-bottom: 60px;
}
.partners__list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 102px;
}
@media (max-width: 1200px) {
  .partners__list {
    gap: 40px;
  }
}
@media (max-width: 768px) {
  .partners__item {
    grid-column: 2 span;
    display: flex;
    justify-content: center;
  }
}
.testimonials {
  background-color: #F4F5F7;
  padding-bottom: 0;
}
@media (max-width: 768px) {
  .testimonials {
    padding-bottom: 60px;
  }
}
.testimonials__container {
  display: flex;
  justify-content: space-between;
}
.testimonials__title {
  margin-bottom: 60px;
}
.testimonials__content {
  max-width: 600px;
}
@media (max-width: 992px) {
  .testimonials__content {
    max-width: 400px;
  }
}
@media (max-width: 768px) {
  .testimonials__content {
    width: 100%;
    max-width: none;
  }
}
.testimonials__img-wrapper {
  width: 72px;
  height: 72px;
  overflow: hidden;
  border-radius: 100%;
  margin-bottom: 24px;
}
.testimonials__img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.testimonials__text {
  line-height: 1.6;
  color: #424551;
  margin-bottom: 24px;
}
.testimonials__author {
  display: block;
  line-height: 1.6;
  font-weight: 700;
  color: #1E212C;
  margin-bottom: 4px;
  padding-right: 110px;
}
.testimonials__position {
  display: block;
  font-size: 14px;
  color: #787A80;
  padding-right: 110px;
}
.testimonials__photo {
  margin-bottom: -60px;
}
@media (max-width: 1200px) {
  .testimonials__photo {
    width: 380px;
    object-fit: cover;
  }
}
@media (max-width: 992px) {
  .testimonials__photo {
    width: 300px;
  }
}
@media (max-width: 768px) {
  .testimonials__photo {
    display: none;
  }
}
.testimonials__slider-nav {
  position: absolute;
  bottom: 0;
  right: 0;
}
.testimonials__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  transition: all 0.3s ease-in-out;
}

.facts {
  padding-top: 180px;
  position: relative;
}
@media (max-width: 768px) {
  .facts {
    padding-top: 80px;
  }
}
.facts::before {
  content: "";
  background-image: url(../img/facts-bg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 570px;
  height: 1065px;
  position: absolute;
  left: -9px;
  top: -195px;
  z-index: -1;
}
.facts__title {
  margin-bottom: 50px;
}
.facts__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 48px;
  margin: 0 auto;
  min-width: 736px;
}
@media (max-width: 768px) {
  .facts__list {
    grid-template-columns: repeat(2, 1fr);
    min-width: 0;
    gap: 36px;
  }
}
@media (max-width: 576px) {
  .facts__list {
    display: block;
  }
}
@media (max-width: 576px) {
  .facts__item {
    margin-bottom: 30px;
  }
}

@media (max-width: 768px) {
  .facts-element {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.facts-element__circle {
  position: relative;
  width: 148px;
  height: 148px;
  margin-bottom: 20px;
}
.facts-element__circle svg {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
.facts-element__circle .progress {
  transform: rotate(-90deg) translateX(24px);
  transform-origin: center;
  stroke: var(--progress-stroke);
  animation: anim 2s linear forwards;
}
@keyframes anim {}
.facts-element__circle .progress-bg {
  stroke: var(--progress-bg-stroke);
}
.facts-element__circle--first-color {
  --progress-stroke: #F89828;
  --progress-bg-stroke: rgba($color: #F89828, $alpha: 0.3);
}
.facts-element__circle--second-color {
  --progress-stroke: #F52F6E;
  --progress-bg-stroke: rgba($color: #F52F6E, $alpha: 0.3);
}
.facts-element__circle--third-color {
  --progress-stroke: #5A87FC;
  --progress-bg-stroke: rgba($color: #5A87FC, $alpha: 0.3);
}
.facts-element__circle--fourth-color {
  --progress-stroke: #03CEA4;
  --progress-bg-stroke: rgba($color: #03CEA4, $alpha: 0.3);
}
.facts-element__value {
  position: absolute;
  left: 50%;
  top: 50%;
  font-size: 32px;
  font-weight: 700;
  color: #1E212C;
  transform: translate(-50%, -50%);
}
.facts-element__text {
  font-size: 14px;
  color: #1E212C;
}

.recent-news__title {
  margin-bottom: 60px;
}
.recent-news__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 255px);
  gap: 30px;
}
.recent-news__item {
  grid-column: 5 span;
  grid-row: 1 span;
}
.recent-news__item:first-child {
  grid-column: 7 span;
  grid-row: 2 span;
}
@media (max-width: 768px) {
  .recent-news__item:first-child {
    grid-column: 12 span;
    grid-row: 2 span;
  }
}
@media (max-width: 768px) {
  .recent-news__item {
    grid-column: 12 span;
    grid-row: 1 span;
  }
}

.recent-article {
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.16), 0px 30px 24px -10px rgba(154, 156, 165, 0.1), 0px 12px 10px -6px rgba(154, 156, 165, 0.08), 0px 4px 4px -4px rgba(30, 33, 44, 0.05);
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
}
.recent-article:hover .recent-article__link {
  color: #FF5A30;
}
.recent-article__img {
  margin-bottom: 16px;
  overflow: hidden;
  transition: all 1s ease-in-out;
}
.recent-article__img:hover {
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .recent-article__img {
    width: 100%;
    object-fit: cover;
  }
}
.recent-article__text {
  padding: 0 24px 24px 24px;
}
.recent-article__title {
  margin-bottom: 8px;
}
.recent-article__link {
  font-size: 20px;
  font-weight: 700;
  color: #1E212C;
  transition: all ease-in-out 0.3s;
}
.recent-article__descr {
  line-height: 1.6;
  color: #424551;
  display: none;
}
.recent-article--big .recent-article__descr {
  display: block;
}
.recent-article--big .recent-article__img {
  margin-bottom: 24px;
}
.recent-article--big .recent-article__title {
  margin-bottom: 12px;
}
.recent-article--big .recent-article__meta {
  margin-bottom: 16px;
}

.recent-meta__item {
  font-size: 14px;
  color: #787A80;
  position: relative;
}
.recent-meta__item:not(:last-child) {
  margin-right: 24px;
}
.recent-meta__item:not(:last-child):after {
  content: "";
  position: absolute;
  top: 2px;
  right: -13px;
  width: 1px;
  height: 11px;
  color: inherit;
  background-color: #787A80;
}
.recent-meta__item--comment {
  background-image: url(../img/comments.svg);
  background-repeat: no-repeat;
  background-size: 16px 16px;
  background-position: left center;
  padding-left: 24px;
}

.contacts-section {
  background-image: url("../img/contacts-bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #787A80;
}
.contacts-section__container {
  display: flex;
  justify-content: flex-end;
}
.contacts-section__form {
  background-color: #fff;
}

.contacts-form {
  max-width: 495px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 48px 40px;
  border-radius: 4px;
  box-shadow: 0px 60px 80px -20px rgba(30, 33, 44, 0.16), 0px 26px 24px -10px rgba(30, 33, 44, 0.1), 0px 12px 10px -6px rgba(30, 33, 44, 0.08), 0px 4px 4px -4px rgba(30, 33, 44, 0.05);
  margin-bottom: 20px;
}
@media (max-width: 576px) {
  .contacts-form {
    padding: 48px 20px;
  }
}
.contacts-form__title {
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  color: #1E212C;
  margin-bottom: 24px;
}

.form-field__input--big {
  height: 66px;
  resize: none;
}

.footer {
  background-color: #1E212C;
  padding: 80px 0;
  color: rgba(255, 255, 255, 0.6);
}
.footer__container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}
.footer__logo {
  margin-right: 60px;
}
.footer__left {
  grid-column: 7 span;
  margin-bottom: 73px;
}
@media (max-width: 768px) {
  .footer__left {
    grid-column: 12 span;
    margin-bottom: 40px;
  }
}
.footer__top {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.footer__form {
  grid-column: 5 span;
}
@media (max-width: 768px) {
  .footer__form {
    grid-column: 12 span;
    margin-bottom: 30px;
  }
}
.footer__address {
  grid-column: 4 span;
  margin-bottom: 60px;
}
@media (max-width: 576px) {
  .footer__address {
    grid-column: 12 span;
  }
}
.footer__nav {
  grid-column: 8 span;
}
@media (max-width: 576px) {
  .footer__nav {
    grid-column: 12 span;
  }
}
.footer__descr {
  width: 100%;
  font-size: 14px;
  max-width: 495px;
}
.footer__copyright {
  grid-column: 12 span;
  font-size: 12px;
  color: #fff;
}
.footer__copyright span {
  padding-left: 22px;
  background-image: url(../img/heart.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 16px 16px;
}

.network {
  display: flex;
  align-items: center;
}
.network__item:not(:last-child) {
  margin-right: 16px;
}
.network__link {
  display: block;
  width: 24px;
  height: 24px;
}
.network__link:hover .network__icon {
  opacity: 1;
}
.network__icon {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: contain;
  opacity: 0.6;
  fill: #fff;
  transition: all ease 0.3s;
}

.footer-form__subtitle {
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 24px;
}
.footer-form__btn {
  margin: 0;
  padding: 12px 19px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  letter-spacing: normal;
}
.footer-form__items {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
.footer-form__label {
  margin: 0;
}
.footer-form__input {
  margin: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: rgba(255, 255, 255, 0.12) !important;
  border: 1px solid rgba(255, 255, 255, 0.25);
}

.footer-address {
  font-style: normal;
}
.footer-address__title {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 13px;
}
@media (max-width: 768px) {
  .footer-address__title {
    font-size: 18px;
  }
}
.footer-address__item {
  display: block;
}
.footer-address__item:not(:last-child) {
  margin-bottom: 4px;
}
.footer-address__item a {
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.6);
  margin-bottom: 4px;
  transition: all ease 0.3s;
}
.footer-address__item a:hover {
  color: white;
}
.footer-address__name {
  color: #fff;
}

.footer-nav {
  padding-left: 54px;
  display: flex;
}
@media (max-width: 576px) {
  .footer-nav {
    padding-left: 0;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
.footer-nav__col:not(:last-child) {
  padding-right: 125px;
}
@media (max-width: 768px) {
  .footer-nav__col:not(:last-child) {
    padding-right: 24px;
  }
}
.footer-nav__title {
  font-size: 20px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 13px;
}
@media (max-width: 768px) {
  .footer-nav__title {
    font-size: 18px;
  }
}
.footer-nav__list {
  padding-inline-start: 0;
}
.footer-nav__item:not(:last-child) {
  margin-bottom: 4px;
}
.footer-nav__link {
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.6);
  transition: all ease 0.3s;
}
.footer-nav__link:hover {
  color: white;
}

.page-hero {
  padding-top: 152px;
  padding-bottom: 180px;
  background-repeat: no-repeat;
  background-color: #F4F5F7;
  background-size: contain;
  background-position: right center;
}
.page-hero--services {
  background-image: url("../img/services-page.png");
}
.page-hero--work {
  background-image: url("../img/bg-work.png");
}
.page-hero--inner {
  background-image: url("../img/bg-services-detail.png");
  background-position: right -40px center;
}
.page-hero--project {
  background-color: #fff;
  background-image: url("../img/bg-project.png");
}
.page-hero--about {
  background-color: #F4F5F7;
  background-image: url("../img/bg-about.png");
}
.page-hero--news {
  background-color: #F4F5F7;
  background-image: url("../img/bg-news.png");
}
.page-hero--contacts {
  background-color: #F4F5F7;
  background-image: url("../img/bg-contacts.png");
}
@media (max-width: 1200px) {
  .page-hero {
    background-image: none;
    padding-bottom: 90px;
  }
}
.page-hero__breadcrumbs {
  margin-bottom: 40px;
}
.page-hero__title {
  max-width: 600px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 72px;
  line-height: 1.3;
  color: #1E212C;
  letter-spacing: 1px;
  margin-bottom: 24px;
}
@media (max-width: 768px) {
  .page-hero__title {
    font-size: 56px;
  }
}
@media (max-width: 576px) {
  .page-hero__title {
    font-size: 36px;
  }
}
.page-hero__descr {
  max-width: 600px;
  font-size: 20px;
  line-height: 1.5;
  color: #787A80;
}
@media (max-width: 768px) {
  .page-hero__descr {
    font-size: 16px;
  }
}

.services {
  position: relative;
  overflow: hidden;
  background-color: #fff;
  padding-top: 120px;
}
.services__item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
}
.services__item:not(:last-child) {
  margin-bottom: 118px;
}
@media (max-width: 768px) {
  .services__item:not(:last-child) {
    margin-bottom: 0;
  }
}
.services__item--reverse .services__info {
  order: -1;
  padding-left: 0;
}
@media (max-width: 768px) {
  .services__item--reverse .services__info {
    order: initial;
  }
}
.services__item--reverse .services__image-box {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 768px) {
  .services__item {
    gap: 0;
  }
}
.services__image-box {
  grid-column: 6 span;
}
@media (max-width: 768px) {
  .services__image-box {
    grid-column: 12 span;
  }
}
.services__img {
  object-fit: cover;
}
.services__info {
  grid-column: 6 span;
  padding: 76px 25px;
}
@media (max-width: 768px) {
  .services__info {
    grid-column: 12 span;
    align-items: center;
    text-align: center;
  }
}
.services__title {
  margin-bottom: 24px;
}
.services__descr {
  color: #787A80;
  line-height: 1.6;
  margin-bottom: 50px;
}
.services__bg1 {
  position: absolute;
  bottom: -248px;
  left: 0;
}
.services__bg2 {
  position: absolute;
  top: 500px;
  right: 0;
}

.offer {
  padding: 128px 0;
  background-color: #fff;
}
@media (max-width: 992px) {
  .offer {
    padding: 80px 0;
  }
}
.offer__container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
}
@media (max-width: 992px) {
  .offer__container {
    display: block;
  }
}
.offer__img-box {
  grid-column: 7 span;
}
.offer__content {
  padding-top: 5px;
  grid-column: 5 span;
  padding-left: 75px;
}
@media (max-width: 1200px) {
  .offer__content {
    padding-left: 35px;
  }
}
@media (max-width: 992px) {
  .offer__content {
    padding-top: 30px;
  }
}
.offer__title {
  font-size: 46px;
  font-family: "Lato", sans-serif;
  font-weight: 900;
  color: #1E212C;
  line-height: 1.3;
  margin-bottom: 55px;
}
.offer__accordion:not(:last-of-type) {
  margin-bottom: 13px;
}
.offer__accordion .accordion__control {
  position: relative;
  text-align: left;
  padding-right: 20px;
  padding-left: 44px;
}
.offer__accordion .accordion-title {
  font-weight: 700;
  font-size: 28px;
  color: #1E212C;
  line-height: 1.5;
}
.offer__accordion .accordion__content {
  padding: 13px 0;
  padding-right: 20px;
  padding-left: 44px;
}
.offer__accordion .accordion__content p {
  margin: 0;
  font-size: 16px;
  line-height: 1.6;
  color: #787A80;
}
.offer__accordion .accordion__icon {
  position: absolute;
  display: block;
  width: 22px;
  height: 22px;
  left: 0;
  top: 0;
}
.offer__accordion .accordion__icon::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 100px;
  width: 100%;
  height: 2px;
  background-color: #FF5A30;
  transform: translateY(-50%);
}
.offer__accordion .accordion__icon::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 0;
  border-radius: 100px;
  width: 2px;
  height: 100%;
  background-color: #FF5A30;
  transition: opacity 0.3s ease-in-out;
}
.offer .is-open .accordion__icon {
  transform: none;
}
.offer .is-open .accordion__icon::after {
  opacity: 0;
}

.how-we-do {
  padding: 45px 0;
  padding-bottom: 120px;
}
.how-we-do__title {
  margin-bottom: 60px;
}
@media (max-width: 576px) {
  .how-we-do__title {
    text-align: center;
  }
}
.how-we-do__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
  counter-reset: section;
}
.how-we-do__item {
  grid-column: 3 span;
  display: flex;
  flex-direction: column;
  position: relative;
}
.how-we-do__item::before {
  content: "0" counter(section);
  counter-increment: section;
  font-weight: 700;
  font-size: 46px;
  line-height: 1.3;
  color: #D7DADD;
  margin-bottom: 30px;
}
.how-we-do__item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  top: 30px;
  height: 1px;
  width: 70%;
  background-image: url("../img/line.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
@media (max-width: 992px) {
  .how-we-do__item:not(:last-child)::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .how-we-do__item {
    grid-column: 6 span;
  }
}
@media (max-width: 576px) {
  .how-we-do__item {
    grid-column: 12 span;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}
.how-we-do__subtitle {
  font-size: 20px;
  font-weight: 700;
  color: #1E212C;
  margin-bottom: 9px;
}
.how-we-do__descr {
  color: #787A80;
}

.benefits {
  background-color: #1E212C;
  padding-bottom: 0;
  color: #fff;
}
.benefits__title {
  color: #fff;
}
.benefits__descr {
  color: rgba(255, 255, 255, 0.6);
}
.benefits__list {
  --mission-gap: 94px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(var(--mission-gap) * 2);
  margin-bottom: 60px;
}
@media (max-width: 1200px) {
  .benefits__list {
    --mission-gap: 34px;
  }
}
@media (max-width: 992px) {
  .benefits__list {
    --mission-gap: 14px;
  }
}
@media (max-width: 768px) {
  .benefits__list {
    display: block;
  }
}
.benefits__item {
  text-align: center;
  padding-top: 70px;
  background-size: 48px 48px;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}
.benefits__item:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.benefits__item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: calc(-1 * var(--mission-gap));
  top: 0;
  width: 1px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/divider.png);
}
@media (max-width: 768px) {
  .benefits__item:not(:last-child)::after {
    display: none;
  }
}
@media (max-width: 768px) {
  .benefits__item {
    margin-bottom: 30px;
  }
}
.benefits__subtitle {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 8px;
}
.benefits__text {
  line-height: 1.56;
  color: rgba(255, 255, 255, 0.6);
}
.benefits__item--quality {
  background-image: url(../img/ic-contract.svg);
}
.benefits__item--safety {
  background-image: url(../img/ic-helmet.svg);
}
.benefits__item--comfort {
  background-image: url(../img/ic-camera.svg);
}
.benefits__box {
  text-align: center;
  transform: translateY(50%);
}
.related {
  padding: 160px 0 100px;
}
.related__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 48px;
}
.related__title {
  margin: 0;
  max-width: 710px;
  padding-bottom: 12px;
}
@media (max-width: 576px) {
  .related__btn {
    padding: 5px 15px;
  }
}
.related__slider {
  padding: 50px 10px;
  margin: -50px -10px;
}
.related__learn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 85px 0 0 0;
}
.related__subtitle {
  margin-right: 40px;
  font-size: 28px;
  font-weight: 700;
  color: #1E212C;
}
@media (max-width: 576px) {
  .related__subtitle {
    font-size: 18px;
    margin-right: 20px;
  }
}

.related-item {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  background-color: #fff;
}
.related-item__img {
  margin-bottom: 16px;
}
.related-item__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 10px;
}
.related-item__title {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #1E212C;
  text-align: center;
  margin-bottom: 4px;
}
@media (max-width: 992px) {
  .related-item__title {
    min-height: 54px;
  }
}
.related-item__desc {
  display: inline-block;
  font-size: 14px;
  text-align: center;
  color: #787A80;
}
.related-item__btn {
  display: none;
  margin-top: 24px;
  font-size: 14px;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  width: 48px;
  height: 48px;
  transition: all 0.3s ease-in-out;
}

.pricing {
  background-color: #F4F5F7;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;
}
.pricing__img {
  position: absolute;
  top: 0;
  left: 0;
}
.pricing__title {
  text-align: center;
}
.pricing__subtitle {
  text-align: center;
  color: #787A80;
  margin-bottom: 60px;
}
.pricing__table {
  z-index: 5;
}

.table-wrapper {
  position: relative;
  z-index: 5;
  overflow: hidden;
  border: 1px solid #E5E8ED;
  border-collapse: collapse;
  border-radius: 4px;
  width: 100%;
  box-shadow: var(--main-shadow);
  background-color: #fff;
}
@media (max-width: 768px) {
  .table-wrapper {
    overflow-x: auto;
  }
}

.table {
  border-collapse: collapse;
  width: 100%;
}
@media (max-width: 768px) {
  .table {
    width: 1200px;
  }
}
.table__row:last-child .table__col {
  padding: 30px 0;
}
.table__th {
  padding-top: 31px;
  padding-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
  color: #1E212C;
  text-align: left;
}
.table__th:first-child {
  padding: 0 24px;
}
.table__heading {
  text-transform: uppercase;
  font-size: 24px;
  text-align: center;
  margin-bottom: 2px;
}
.table__per {
  font-size: 18px;
  font-weight: 700;
  color: #787A80;
  text-align: center;
}
.table__body .table__row:nth-child(odd) {
  background-color: #F4F5F7;
}
.table__col {
  padding: 17px 0;
  color: #424551;
  text-align: center;
}
.table__col:first-child {
  text-align: left;
  padding-left: 24px;
}
.table__col .btn {
  padding: 0px 24px;
  font-size: 12px;
  line-height: 3;
}
@media (max-width: 768px) {
  .table__col .btn {
    padding: 0 4px;
  }
}
.table__check {
  width: 24px;
  height: 24px;
  font-size: 0;
  display: inline-block;
  background-image: url("../img/mark.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.portfolio {
  padding-top: 0;
}
.portfolio__tabs {
  display: flex;
  align-items: center;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .portfolio__tabs {
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
  }
}
.portfolio__item {
  width: calc((100% - 80px) / 5);
  transform: translateY(-40%);
}
.portfolio__item:not(:last-child) {
  margin-right: 20px;
}
@media (max-width: 768px) {
  .portfolio__item {
    width: calc((100% - 80px) / 3);
    margin-bottom: 20px;
    transform: translateY(-20%);
  }
}
@media (max-width: 576px) {
  .portfolio__item {
    width: calc((100% - 80px) / 2);
  }
}
.portfolio__btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 10px;
  background-color: #fff;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  transition: all ease 0.3s;
}
.portfolio__btn:hover {
  background-color: #FF5A30;
}
.portfolio__btn:hover .portfolio__icon {
  fill: #fff;
}
.portfolio__btn:hover .portfolio__text {
  color: #fff;
}
.portfolio__btn--active {
  pointer-events: none;
  background-color: #FF5A30;
}
.portfolio__btn--active .portfolio__icon {
  fill: #fff;
}
.portfolio__btn--active .portfolio__text {
  color: #fff;
}
@media (max-width: 992px) {
  .portfolio__btn {
    min-height: 164px;
  }
}
.portfolio__icon {
  width: 48px;
  height: 48px;
  display: block;
  object-fit: cover;
  fill: #FF5A30;
  margin-bottom: 16px;
  pointer-events: none;
  transition: all ease 0.3s;
}
.portfolio__text {
  font-weight: 700;
  color: #9A9CA5;
  pointer-events: none;
  transition: all ease 0.3s;
}
.portfolio__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
}
@media (max-width: 576px) {
  .portfolio__list {
    gap: 0;
    row-gap: 30px;
  }
}
.portfolio__element {
  grid-column: 4 span;
}
.portfolio__element--visible {
  display: block !important;
}
@media (max-width: 768px) {
  .portfolio__element {
    grid-column: 6 span;
  }
}
@media (max-width: 576px) {
  .portfolio__element {
    grid-column: 12 span;
  }
}

.project-goal {
  padding-top: 0;
}
.project-goal__container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
}
@media (max-width: 768px) {
  .project-goal__container {
    display: block;
  }
}
.project-goal__left {
  grid-column: 7 span;
}
.project-goal__title {
  margin-bottom: 40px;
}
.project-goal__text {
  max-width: 600px;
  font-size: 18px;
  color: #424551;
}
.project-goal__text:not(:last-child) {
  margin-bottom: 16px;
}
.project-goal__right {
  grid-column: 5 span;
}
.project-goal__info {
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  padding: 40px 60px;
}
@media (max-width: 992px) {
  .project-goal__list {
    padding: 40px 20px;
  }
}
.project-goal__item {
  display: flex;
}
.project-goal__item:not(:last-child) {
  margin-bottom: 16px;
}
.project-goal__category {
  width: 35%;
  line-height: 1.6;
  font-weight: 700;
  color: #1E212C;
  text-transform: uppercase;
}
@media (max-width: 992px) {
  .project-goal__category {
    width: 40%;
  }
}
.project-goal__value {
  width: 65%;
  line-height: 1.6;
  color: #787A80;
}

.decisions {
  padding: 80px 0 114px;
  background-color: #F4F5F7;
}
.decisions__container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
}
@media (max-width: 768px) {
  .decisions__container {
    display: block;
  }
}
.decisions__left {
  grid-column: 5 span;
  position: relative;
}
@media (max-width: 768px) {
  .decisions__left {
    display: none;
  }
}
.decisions__img {
  position: absolute;
  top: 0;
  left: 0;
}
.decisions__right {
  grid-column: 7 span;
  padding-left: 104px;
}
@media (max-width: 992px) {
  .decisions__right {
    padding-left: 30px;
  }
}
@media (max-width: 576px) {
  .decisions__right {
    padding-left: 0;
  }
}
.decisions__title {
  margin-bottom: 60px;
}
@media (max-width: 576px) {
  .decisions__title {
    text-align: center;
  }
}
.decisions__list {
  max-width: 600px;
}
.decisions__item {
  position: relative;
  padding-left: 30px;
  line-height: 1.6;
}
.decisions__item:not(:last-child) {
  margin-bottom: 20px;
}
.decisions__item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 3px;
  width: 20px;
  height: 20px;
  background-image: url("../img/decisions.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.similar {
  padding: 180px 0 120px;
}
@media (max-width: 576px) {
  .similar {
    padding: 40px 0;
  }
}

.achieve {
  background-image: url("../img/bg-achieve.png");
  background-repeat: no-repeat;
  background-position: right bottom -350px;
}
.achieve__list {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
  margin-bottom: 240px;
}
@media (max-width: 992px) {
  .achieve__list {
    margin-bottom: 120px;
  }
}
.achieve__item {
  grid-column: 3 span;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: 70px;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 48px 48px;
}
.achieve__item--recom {
  background-image: url("../img/ic-like.svg");
  fill: #9A9CA5;
}
.achieve__item--aparts {
  background-image: url("../img/ic-painting.svg");
  fill: #9A9CA5;
}
.achieve__item--spec {
  background-image: url("../img/ic-helmet-grey.svg");
  fill: #9A9CA5;
}
.achieve__item--finish {
  background-image: url("../img/ic-pantone.svg");
  fill: #9A9CA5;
}
@media (max-width: 992px) {
  .achieve__item {
    grid-column: 6 span;
  }
}
@media (max-width: 576px) {
  .achieve__item {
    grid-column: 12 span;
  }
}
.achieve__value {
  font-weight: 700;
  font-size: 46px;
  line-height: 1.3;
  color: #FF5A30;
  margin-bottom: 8px;
}
@media (max-width: 1200px) {
  .achieve__value {
    font-size: 36px;
  }
}
@media (max-width: 576px) {
  .achieve__value {
    font-size: 28px;
  }
}
.achieve__text {
  font-size: 14px;
  color: #1E212C;
}
.achieve__content {
  display: flex;
}
@media (max-width: 992px) {
  .achieve__content {
    display: block;
  }
}
@media (max-width: 1200px) {
  .achieve__img {
    width: 45%;
    object-fit: cover;
  }
}
@media (max-width: 992px) {
  .achieve__img {
    width: 60%;
    object-fit: cover;
  }
}
@media (max-width: 992px) {
  .achieve__img {
    width: 60%;
    object-fit: cover;
  }
}
@media (max-width: 768px) {
  .achieve__img {
    width: 100%;
  }
}
.achieve__info {
  position: relative;
  z-index: 10;
  background-color: #fff;
  box-shadow: 0px 80px 80px -20px rgba(154, 156, 165, 0.08), 0px 30px 24px -10px rgba(154, 156, 165, 0.05), 0px 12px 10px -6px rgba(154, 156, 165, 0.04), 0px 4px 4px -4px rgba(30, 33, 44, 0.03);
  border-radius: 4px;
  width: 735px;
  left: -30px;
  top: -120px;
  padding: 80px 105px 80px 135px;
}
@media (max-width: 992px) {
  .achieve__info {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .achieve__info {
    padding: 60px 40px 60px 100px;
  }
}
@media (max-width: 576px) {
  .achieve__info {
    width: 100%;
    left: 0;
    top: 40px;
    padding: 40px 10px 10px 10px;
  }
}
.achieve__info::before {
  content: "";
  position: absolute;
  background-image: url("../img/braces.svg");
  background-repeat: no-repeat;
  fill: #FF5A30;
  width: 28px;
  height: 20px;
  top: 80px;
  left: 80px;
}
@media (max-width: 768px) {
  .achieve__info::before {
    top: 40px;
    left: 60px;
  }
}
@media (max-width: 576px) {
  .achieve__info::before {
    top: 10px;
    left: 10px;
  }
}
.achieve__top {
  font-size: 20px;
  font-weight: 700;
  line-height: 1.5;
  color: #1E212C;
  margin-bottom: 60px;
}
.achieve__top p:not(:last-child) {
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .achieve__top {
    font-size: 18px;
  }
}
@media (max-width: 576px) {
  .achieve__top {
    font-size: 16px;
    margin-bottom: 40px;
  }
}
.achieve__bottom {
  position: relative;
  font-size: 14px;
  color: #787A80;
}
.achieve__bottom::after {
  content: "";
  position: absolute;
  background-image: url("../img/sign.svg");
  background-repeat: no-repeat;
  background-size: cover;
  fill: #FF5A30;
  width: 100px;
  height: 68px;
  top: 0px;
  right: 0px;
}
@media (max-width: 576px) {
  .achieve__bottom::after {
    width: 50px;
    height: 34px;
    left: 0px;
    top: 50px;
  }
}
@media (max-width: 576px) {
  .achieve__bottom {
    padding-bottom: 50px;
  }
}
.achieve__author {
  color: #1E212C;
  line-height: 1.6;
  font-weight: 700;
  margin-bottom: 4px;
}

@media (max-width: 576px) {
  .categories {
    padding: 40px 0;
  }
}
.categories__title {
  margin-bottom: 50px;
  text-align: center;
}
.categories__nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 70px;
}
.categories__item {
  width: 16.6666666667%;
}
@media (max-width: 992px) {
  .categories__item {
    width: 33.3333333333%;
  }
}
@media (max-width: 576px) {
  .categories__item {
    width: 100%;
  }
}
.categories__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  color: #9A9CA5;
  border: 1px solid transparent;
  text-transform: initial;
  line-height: 1.6;
  transition: all ease 0.3s;
}
.categories__btn--active {
  border-color: #FF5A30;
  color: #FF5A30;
}
.categories__news {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
  row-gap: 60px;
  margin-bottom: 60px;
}
@media (max-width: 768px) {
  .categories__news {
    gap: 0;
    row-gap: 60px;
  }
}
.categories__article {
  grid-column: 6 span;
}
.categories__article--visible {
  display: block !important;
}
@media (max-width: 768px) {
  .categories__article {
    grid-column: 12 span;
  }
}
.categories__pag {
  display: flex;
  justify-content: center;
}
.categories__page {
  margin-right: 20px;
}
.categories__link {
  font-weight: 700;
  color: #424551;
  font-size: 16px;
  line-height: 1.6;
  transition: color ease 0.3s;
}
.categories__link--active {
  color: #FF5A30;
}
.categories__icon {
  display: block;
  height: 24px;
  width: 24px;
  fill: #424551;
  object-fit: cover;
}

.contacts__content {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 1200px) {
  .contacts__content {
    justify-content: center;
  }
}
@media (max-width: 992px) {
  .contacts__content {
    display: block;
    text-align: center;
    align-items: center;
  }
}
.contacts__img {
  margin-right: 20px;
  object-fit: cover;
}
@media (max-width: 1200px) {
  .contacts__img {
    width: 36%;
  }
}
@media (max-width: 992px) {
  .contacts__img {
    width: 50%;
    margin-bottom: 30px;
  }
}

.contactus-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 600px;
}
@media (max-width: 992px) {
  .contactus-form {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .contactus-form {
    display: block;
  }
}
.contactus-form__field {
  width: 48%;
  margin-right: 0px;
  margin-bottom: 24px;
}
.contactus-form__field--big {
  width: 100%;
}
.contactus-form__field--big .form-field__input--big {
  height: 126px;
}
@media (max-width: 576px) {
  .contactus-form__field {
    width: 100%;
  }
}
.contactus-form__set {
  border: none;
  padding: 0;
  padding-left: 24px;
  width: 47.5%;
}
.contactus-form__set .form-field__caption {
  margin-bottom: 21px;
}
@media (max-width: 768px) {
  .contactus-form__set {
    width: 100%;
    padding: 0;
  }
}
.contactus-form__select {
  border: 1px solid #D7DADD;
  background-color: #F4F5F7;
  border-radius: 4px;
  width: 100%;
  height: 44px;
  padding-left: 16px;
  color: #424551;
  appearance: none;
  background-image: url("../img/chevron-down.svg");
  background-repeat: no-repeat;
  background-position: right 16px center;
  background-size: 13px;
}
.contactus-form__option {
  padding: 16px;
  line-height: 1.6;
}
.contactus-form__radio:not(:last-child) {
  margin-right: 4px;
}
.contactus-form__var {
  display: flex;
  flex-wrap: wrap;
}
.contactus-form__bottom {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 39px;
}
@media (max-width: 992px) {
  .contactus-form__bottom {
    width: 100%;
  }
}
@media (max-width: 576px) {
  .contactus-form__bottom {
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }
}

.contacts-info {
  background-color: #F4F5F7;
  padding-top: 80px;
}
.contacts-info__list {
  --mission-gap: 94px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(var(--mission-gap) * 2);
  margin-bottom: 27px;
}
@media (max-width: 1200px) {
  .contacts-info__list {
    --mission-gap: 34px;
  }
}
@media (max-width: 992px) {
  .contacts-info__list {
    --mission-gap: 12px;
  }
}
@media (max-width: 768px) {
  .contacts-info__list {
    grid-template-columns: repeat(1, 1fr);
  }
}
.contacts-info__item {
  position: relative;
}
.contacts-info__item:nth-child(2) {
  display: flex;
  flex-direction: column;
}
.contacts-info__item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: calc(-1 * var(--mission-gap));
  top: 0;
  width: 1px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/divider.png);
}
@media (max-width: 768px) {
  .contacts-info__item {
    margin-bottom: 12px;
  }
}
.contacts-info__subtitle {
  font-weight: 700;
  font-size: 28px;
  line-height: 1.5;
  color: #1E212C;
  margin-bottom: 24px;
}
@media (max-width: 992px) {
  .contacts-info__subtitle {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .contacts-info__subtitle {
    margin-bottom: 12px;
  }
}
.contacts-info__address {
  font-style: normal;
  line-height: 1.6;
  color: #1E212C;
  margin-bottom: 4px;
}
.contacts-info__descr {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .contacts-info__descr {
    margin-top: 10px;
  }
}
.contacts-info__link {
  color: #FF5A30;
  text-decoration: underline;
}
.contacts-info__text {
  color: #787A80;
}
.contacts-info__contacts {
  color: #1E212C;
}

.find-us {
  padding-top: 0;
  background-color: #F4F5F7;
}
.find-us__title {
  margin-bottom: 40px;
}
.find-us__network {
  justify-content: center;
}
.find-us__network .network__item:not(:last-child) {
  margin-right: 22px;
}
.find-us__network .network__link {
  width: 32px;
  height: 32px;
}
.find-us__network .network__icon {
  fill: #787A80;
  opacity: 1;
}

.core-values {
  padding-top: 80px;
  background-color: #F4F5F7;
}
.core-values__list {
  --mission-gap: 94px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: calc(var(--mission-gap) * 2);
  margin-bottom: 27px;
}
@media (max-width: 992px) {
  .core-values__list {
    --mission-gap: 30px;
  }
}
@media (max-width: 576px) {
  .core-values__list {
    display: block;
  }
}
.core-values__item {
  text-align: center;
  padding-top: 70px;
  background-size: 48px 48px;
  background-position: top center;
  background-repeat: no-repeat;
  position: relative;
}
.core-values__item:nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.core-values__item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: calc(-1 * var(--mission-gap));
  top: 0;
  width: 1px;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(../img/divider.png);
}
.core-values__subtitle {
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  color: #1E212C;
  margin-bottom: 8px;
}
.core-values__text {
  line-height: 1.56;
  color: #787A80;
}
.core-values__item--quality {
  background-image: url(../img/like.svg);
}
.core-values__item--safety {
  background-image: url(../img/safety.svg);
}
.core-values__item--comfort {
  background-image: url(../img/comfort.svg);
}

.history {
  padding: 120px 0;
  background-color: #F4F5F7;
}
@media (max-width: 576px) {
  .history {
    padding: 60px 0;
  }
}
.history__top {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 48px;
}
.history__title {
  margin: 0;
  max-width: 710px;
  padding-bottom: 12px;
}
.history__content {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 30px;
}
.history__right {
  overflow: hidden;
  grid-column: 8 span;
}
@media (max-width: 576px) {
  .history__right {
    grid-column: 12 span;
  }
}
.history__img {
  margin-bottom: 36px;
}
@media (max-width: 992px) {
  .history__img {
    object-fit: cover;
  }
}
.history__descr {
  line-height: 1.6;
  color: #424551;
}

.history-nav {
  grid-column: 4 span;
  position: relative;
}
@media (max-width: 576px) {
  .history-nav {
    grid-column: 12 span;
  }
}
.history-nav:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 100%;
  background-color: rgba(154, 156, 165, 0.5);
}
.history-nav__item:not(:last-child) {
  margin-bottom: 20px;
}
.history-nav__btn {
  font-weight: 700;
  font-size: 28px;
  color: #9A9CA5;
  line-height: 1.5;
  padding-left: 30px;
  position: relative;
  transition: all ease 0.3s;
}
.history-nav__btn::before {
  content: "";
  position: absolute;
  top: 13px;
  left: 1px;
  width: 1px;
  height: 16px;
  background-color: #F4F5F7;
}
.history-nav__btn::after {
  content: "";
  position: absolute;
  border-radius: 100%;
  top: 17px;
  left: -3px;
  width: 8px;
  height: 8px;
  background-color: #9A9CA5;
}
.history-nav__btn--active {
  color: #FF5A30;
}
@media (max-width: 992px) {
  .history-nav__btn {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .history-nav__btn {
    font-size: 17px;
  }
}

.partners-about {
  background-color: #fff;
}
.partners-about--light {
  padding-top: 180px;
  background-color: #fff;
}
.partners-about__title {
  margin-bottom: 60px;
}
.partners-about__descr {
  color: #787A80;
}
.partners-about__list {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 102px;
  margin-bottom: 30px;
}
@media (max-width: 992px) {
  .partners-about__list {
    gap: 60px;
  }
}
@media (max-width: 768px) {
  .partners-about__list {
    grid-template-columns: repeat(3, 1fr);
  }
}
.map {
  padding-top: 80px;
  background-color: #F4F5F7;
}
@media (max-width: 992px) {
  .map__img {
    object-fit: contain;
  }
}
@media (max-width: 992px) {
  .map {
    padding: 60px 0 40px 0;
  }
}
@media (max-width: 576px) {
  .map {
    display: none;
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  padding: 0;
}
.breadcrumbs__item {
  position: relative;
}
.breadcrumbs__item:not(:last-child) {
  margin-right: 22px;
}
.breadcrumbs__item:not(:last-child)::after {
  content: "/";
  position: absolute;
  right: -14px;
  top: 0;
  color: #9A9CA5;
}
.breadcrumbs__link {
  font-size: 14px;
  color: #424551;
}
.breadcrumbs__link:not([href]) {
  color: #9A9CA5;
}
.breadcrumbs__link:focus {
  outline-offset: 2px;
  outline: 2px solid #FF5A30;
}
.breadcrumbs__link:hover {
  color: #FF5A30;
}
.breadcrumbs__link:hover:not([href]) {
  color: #9A9CA5;
}
.breadcrumbs__link:active {
  color: #FC3300;
}

.hero-project__title {
  font-size: 64px;
  line-height: 1.2;
  text-transform: initial;
  letter-spacing: 1px;
  margin-bottom: 60px;
}
.hero-project__images {
  overflow: hidden;
}
.hero-project__slider {
  margin-bottom: 10px;
  position: relative;
}
.hero-project__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.hero-project__img--th {
  border-radius: 4px;
}
.hero-project__nav .swiper-slide {
  border-radius: 4px;
  opacity: 0.6;
  cursor: pointer;
  transition: opacity ease 0.3s;
}
.hero-project__nav .swiper-slide-thumb-active {
  opacity: 1;
}

.project-btn__next {
  position: absolute;
  top: 50%;
  right: 32px;
  z-index: 10;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}
.project-btn__prev {
  position: absolute;
  top: 50%;
  left: 32px;
  z-index: 10;
  padding: 5px;
  background-color: rgba(255, 255, 255, 0.5);
}

.burger {
  display: none;
  cursor: pointer;
  border: none;
  position: relative;
}

@media (max-width: 992px) {
  .burger {
    display: block;
    width: 40px;
    height: 20px;
    top: 0;
    right: 15px;
    z-index: 5;
  }
  .burger span {
    position: absolute;
    background-color: #424551;
    left: 0;
    width: 100%;
    height: 2px;
    top: 9px;
    transition: all 0.3s ease;
  }

  .burger:before,
.burger:after {
    content: "";
    background-color: #424551;
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    transition: all 0.3s ease;
  }

  .burger:before {
    top: 0;
  }

  .burger:after {
    bottom: 0;
  }

  .burger.open span {
    transform: translateY(-100px);
  }

  .burger.open::before {
    transform: rotate(45deg);
    top: 9px;
  }

  .burger.open::after {
    transform: rotate(-45deg);
    bottom: 9px;
  }
}